<template>
	<div class="authorization authorization--login pa-0">
		<v-row no-gutters>
			<v-col cols="12" md="6" lg="5">
				<div class="authorization__form form--login pt-12 d-flex align-center" :class="{red: !isProduction}">

					<div class="form__content">
						<div class="form__content--logo">
							<v-row>
								<v-col cols="12">
									<v-img
										class="ma-auto"
										max-width="243"
										src="/img/logo_dark.png"
									/>
								</v-col>
								<v-col cols="12" v-if="!isProduction">
									<div class="text-center font-weight-500 white--text text-h4">
										Środowisko {{ env.toUpperCase() }}
									</div>
								</v-col>
							</v-row>
						</div>
						<div class="form__content--form mt-4 pa-6">
							<v-row dense>
								<v-col cols="12">
									<h1 class="content__title">Login to WOOKAH Wholesale Order Form</h1>
								</v-col>
								<v-col cols="12">
									<v-text-field
										outlined
										label="Email"
										prepend-icon="fas fa-at"
										hide-details
										class="mb-4"
										v-model="email"
									/>
								</v-col>
								<v-col cols="12">
									<v-text-field
										outlined
										label="Password"
										type="password"
										prepend-icon="fas fa-lock"
										hide-details
										class="mb-2"
										v-model="password"
									/>
								</v-col>
								<v-col cols="12" xs="12" sm="6">
									<v-btn
										block
										large
										class="white--text mb-2"
										color="secondary"
										:to="{name: 'Register'}"
										:disabled="loadingBtnLogin"
										id="login-register"
									>
										Register
									</v-btn>
								</v-col>
								<v-col cols="12" xs="12" sm="6">
									<v-btn
										class="mb-2"
										block
										large
										color="primary"
										@click="login()"
										:loading="loadingBtnLogin"
										id="login-login"
									>
										Login
									</v-btn>
								</v-col>
								<v-col cols="12" class="py-0">
									<v-btn
										id="login-forgot_password"
										text
										color="black"
										class="float-right mb-2"
										:to="{name: 'ForgotPassword'}"
										v-on:keyup.enter="enterLogin"
									>
										Forgot your password?
									</v-btn>
								</v-col>
							</v-row>
						</div>

						<block-carousel class="hidden-md-and-up mt-8" :height="'100%'"/>

					</div>
				</div>
			</v-col>
			<v-col cols="12" md="6" lg="7" class="hidden-sm-and-down">
				<block-carousel/>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import BlockCarousel from "@/views/auth/Block/BlockCarousel";

export default {
	name: 'Login',
	components: {BlockCarousel},
	computed: {
		env() {
			return process.env.VUE_APP_API_ENV;
		},

		isProduction() {
			return this.env === 'prod';
		}
	},
	data: () => ({
		email: '',
		password: '',
		canLogin: true,
		loadingBtnLogin: false,
	}),
	methods: {
		enterLogin() {
			if (this.canLogin && this.email.length > 0 && this.password.length > 0) {
				this.login();
			}
		},

		login() {
			if (this.email.length < 1 || this.password.length < 1) {
				this.$swal({
					icon: 'error',
					title: 'Błąd!',
					text: 'Wszystkie pola są wymagane!',
				})
			}

			this.loadingBtnLogin = true;
			const payload = {
				email: this.email,
				password: this.password,
			};

			this.canLogin = false;
			this.$store.dispatch('AUTH_STORE/login', payload)
				.then(resp => {
					if (resp.data.success) {
						this.$gtag.config({user_id: resp.data.user.dmt_id})
						this.$gtag.event(
							'Login',
							{
								user_id: resp.data.user.dmt_id,
							}
						);
						this.$router.push({name: 'Dashboard'}).catch(() => {
						});
					} else {
						this.$swal({
							icon: 'error',
							title: resp.data.title,
							text: resp.data.message,
						}).then(() => {
							this.canLogin = true;
						});
					}

					this.loadingBtnLogin = false;
				})
				.catch(err => {
					console.error(err)
					this.loadingBtnLogin = false;
					this.$swal({
						icon: 'error',
						title: 'Error!',
						text: 'Something went wrong, please try again!',
					}).then(() => {
						this.canLogin = true;
					});
				});
		},
	},
	created() {
	},
};
</script>
